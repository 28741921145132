import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { Label as BaseLabel } from '@increasecard/typed-components';

const Wrapper = styled.div`
  background: ${({ variant, theme }) => {
    switch (variant) {
      case 'enterprise':
        return `${theme.colorsNew.product.dark}88`;
      case 'increase':
        return theme.colorsNew.increase.regular;
      default:
        return '#18202688';
    }
  }};
  box-shadow: 0px 8px 10px rgba(24, 32, 38, 0.09),
    0px 6px 30px rgba(24, 32, 38, 0.11), 0px 16px 24px rgba(24, 32, 38, 0.13);
  border-radius: 10px;
  display: flex;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @media (min-width: ${MEDIUM}) {
    padding: 24px 48px;
  }
`;

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
  height: 100%;
`;

const Label = styled(BaseLabel)`
  font-weight: bold;
`;

const Value = styled.span`
  color: ${({ theme }) => theme.colorsNew.product.accent};
  font-size: 70px;
  line-height: 1;
  font-weight: bold;
`;

const Gauge = styled.div`
  height: 6px;
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colorsNew.product.dark70};

  &::after {
    content: '';
    height: 8px;
    position: absolute;
    top: -1px;
    left: 0;
    width: ${({ value }) => `${value}%`};
    background: ${({ theme }) => theme.colorsNew.product.accent};
  }
`;

const GaugeValue = styled.div`
  font-weight: bold;
  font-size: 42px;
  line-height: 36px;
`;

const GaugeText = styled.span`
  margin-left: 4px;
  font-weight: normal;
  align-self: center;
  ${({ theme }) => theme.typography.cell}

  @media (min-width: ${MEDIUM}) {
    ${({ theme }) => theme.typography.H3}
    align-self: flex-end;
  }
`;

const GaugePercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
`;

export function Metric({ className, name, type, gaugeText, value, variant }) {
  return (
    <Wrapper className={className} variant={variant}>
      <LabelsContainer>
        <Label dangerouslySetInnerHTML={{ __html: name }} />
        {type === 'gauge' && (
          <GaugePercentage>
            <GaugeValue>{value}%</GaugeValue>
            <GaugeText>{gaugeText}</GaugeText>
          </GaugePercentage>
        )}
      </LabelsContainer>
      {type === 'number' && <Value>{value}</Value>}
      {type === 'gauge' && <Gauge text={gaugeText} value={value} />}
    </Wrapper>
  );
}
