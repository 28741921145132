import React from 'react';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { CTAButton } from '../components/CTAButton';
import { BackgroundBase } from '../backgrounds/BackgroundBase';
import { RoundedSquare } from '../backgrounds/shapes/RoundedSquare';
import { MEDIUM } from '../breakpoints';
import { SuperHeader, H1 } from '../components/Typography';

const Container = styled(BaseContainer)`
  text-align: center;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  padding-top: 132px;
  padding-bottom: 132px;
  @media (min-width: ${MEDIUM}) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const WhiteSquare = styled(RoundedSquare).attrs({
  size: '112px',
  bgColor: theme => theme.colorsNew.gray.white,
})`
  position: absolute;
  right: -40px;
  bottom: -56px;

  @media (min-width: ${MEDIUM}) {
    right: 100px;
    bottom: -56px;
  }
`;

const SquareOutline = styled(RoundedSquare).attrs({
  size: '394px',
  rotation: '45deg',
})`
  border: 3px solid ${({ theme }) => theme.colorsNew.increase.regular};
  position: absolute;
  top: calc(50% - 394px / 2);
  left: calc(10% - 394px / 2);

  @media (min-width: ${MEDIUM}) {
    left: calc(15% + 30px);
  }
`;

const Title = styled(SuperHeader)`
  color: ${({ theme }) => theme.colorsNew.increase.accent};
`;

const Subtitle = styled(H1)``;

const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colorsNew.increase.dark};
  margin: 0 auto;
  padding: 16px 0;

  @media (min-width: ${MEDIUM}) {
    width: max-content;
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-auto-flow: column;
`;

export function ProductsCtaSection({
  title,
  subtitle,
  primaryCTA,
  secondaryCTA,
}) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.increase.dark}
      renderBackground={() => (
        <>
          <WhiteSquare /> <SquareOutline />
        </>
      )}
    >
      <Container>
        <ContentWrapper>
          <Title>{title}</Title>
          <Subtitle
            dangerouslySetInnerHTML={{ __html: subtitle }}
            weight="normal"
            style={{ margin: '8px auto 16px' }}
          />
          <ButtonGroup>
            <CTAButton {...primaryCTA} type="white" />
            {secondaryCTA && (
              <CTAButton {...secondaryCTA} type="white-outline" />
            )}
          </ButtonGroup>
        </ContentWrapper>
      </Container>
    </BackgroundBase>
  );
}
