import React from 'react';
import styled from 'styled-components';
import { Container } from '../components/Container';
import { Grid } from '../components/Grid';
import { MEDIUM, LARGE } from '../breakpoints';
import { RoundedSquare } from '../backgrounds/shapes/RoundedSquare';
import { BackgroundBase } from '../backgrounds/BackgroundBase';
import { Metric } from '../components/Metric';

const MetricsWrapper = styled(Grid)`
  grid-gap: 16px;
  padding: 64px 0 24px;

  @media (min-width: ${MEDIUM}) {
    grid-gap: 24px;
    grid-template-columns: repeat(8, 95px);
    padding: 64px 0 64px;
  }
  @media (min-width: ${LARGE}) {
    grid-template-columns: repeat(8, 115px);
  }
`;

const Wrapper = styled.div``;

const BigSquare = styled(RoundedSquare).attrs({
  size: '694px',
  bgColor: theme => theme.colorsNew.gray.black,
})`
  opacity: 0.4;
  position: absolute;
  left: -230px;
  top: calc(70% - 694px / 2);
`;

export function MetricsSectionIncreaseTheme({ metrics, variant }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.product.dark}
      renderBackground={() => <BigSquare />}
    >
      <Wrapper variant={variant}>
        <Container>
          <MetricsWrapper>
            {metrics.map(({ desktopColumns, id, ...rest }) => (
              <Metric
                className={`grid-span-md-${desktopColumns} grid-span-sm-2`}
                {...rest}
                key={id}
                variant="increase"
              />
            ))}
          </MetricsWrapper>
        </Container>
      </Wrapper>
    </BackgroundBase>
  );
}
