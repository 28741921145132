import React, { useState } from 'react';
import { Container as BaseContainer } from '../components/Container';
import styled, { css } from 'styled-components';
import { H3, Caption } from '@increasecard/typed-components';
import { BackgroundBase } from '../backgrounds/BackgroundBase';
import GatsbyImage from 'gatsby-image';
import { MEDIUM } from '../breakpoints';
import { SuperHeader } from '../components/Typography';

const Container = styled(BaseContainer)`
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  color: ${({ theme }) => theme.colorsNew.gray.black};
`;

const LightGreenRectangle = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colorsNew.increase.light};
  bottom: 0;
  width: 100%;
  height: 680px;
  opacity: 0.4;
  @media (min-width: ${MEDIUM}) {
    height: 400px;
    border-radius: 75px 75px 0px 0px;
  }
`;

function OurSolutionsBackground({ children }) {
  return (
    <BackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      renderBackground={() => <LightGreenRectangle />}
    >
      {children}
    </BackgroundBase>
  );
}

const Solutions = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: center;

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(3, 346px);
    grid-template-rows: 160px;
  }
`;
const SolutionButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 260px;
  padding: 24px 8px 16px;
  border-radius: 12px;

  ${({ active, theme }) =>
    active
      ? css`
          background: ${theme.colorsNew.gray.white};
          box-shadow: ${theme.shadows.elevate};
        `
      : css`
          :hover {
            box-shadow: ${({ theme }) => theme.shadows.elevateCast};
          }
        `}
`;
const SolutionText = styled.div`
  width: 160px;
  text-align: left;
  height: 100%;
`;
const SolutionImage = styled.div`
  height: 100%;
  margin-right: 16px;
`;
const SolutionName = styled(H3)`
  color: ${({ theme }) => theme.colorsNew.increase.regular};
  line-height: 19px;
  margin-bottom: 8px;
`;
const SolutionDescription = styled(Caption).attrs({ as: 'p' })`
  color: ${({ theme }) => theme.colorsNew.increase.dark};
`;

const ImageWrapper = styled.div`
  max-width: 600px;
  margin: 24px auto 40px;
`;

export function OurSolutionsSection({ title, description, solutions }) {
  const [selectedSolution, setSelectedSolution] = useState(solutions[0]);
  return (
    <OurSolutionsBackground>
      <Container>
        <SuperHeader>{title}</SuperHeader>
        <H3
          weight="normal"
          style={{ maxWidth: '36em', margin: '8px auto 32px' }}
        >
          {description}
        </H3>
        <ImageWrapper>
          <GatsbyImage
            {...(selectedSolution.image.childImageSharp ||
              selectedSolution.image.imageSharp.childImageSharp)}
          />
        </ImageWrapper>
        <Solutions>
          {solutions.map(solution => (
            <SolutionButton
              active={selectedSolution === solution}
              onClick={() => setSelectedSolution(solution)}
            >
              <SolutionImage>
                <img alt="" src={solution.icon.imageSharp.publicURL} />
              </SolutionImage>
              <SolutionText>
                <SolutionName
                  dangerouslySetInnerHTML={{ __html: solution.name }}
                />
                <SolutionDescription>
                  {solution.description}
                </SolutionDescription>
              </SolutionText>
            </SolutionButton>
          ))}
        </Solutions>
      </Container>
    </OurSolutionsBackground>
  );
}
